import axios from 'axios'
export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {
                  list:[],
                  isArr:{
                    "Warcraft":[ 
                      {img:require('../assets/img/Warcraft/img_12.png'),title:'魔兽争霸2黑潮',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-12',moreSize:'605.11M',size:'69.1M'},
                      {img:require('../assets/img/Warcraft/img_01.png'),title:'魔兽争霸3八族争霸',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-01',moreSize:'872.36M',size:'1.23G'},
                      {img:require('../assets/img/Warcraft/img_08.png'),title:'魔兽争霸3冰封王座kk平台',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-10-18',moreSize:'31.66M',size:'1.24G'},
                      {img:require('../assets/img/Warcraft/img_02.png'),title:'魔兽争霸3冰封王座',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-07-30',moreSize:'31.67M',size:'1.28G'},
                      {img:require('../assets/img/Warcraft/img_04.png'),title:'魔兽争霸3涅磐重生',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-15',moreSize:'27.34M',size:'3.2G'},
                      {img:require('../assets/img/Warcraft/img_10.png'),title:'魔兽争霸3邪魔复苏',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-15',moreSize:'32.62M',size:'1.85G'},
                    ],
                    "RedAlert":[
                      {img:require('../assets/img/RedAlert/img_01.png'),title:'红色警戒2共和国之辉',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-15',moreSize:'28M',size:'138M'},
                      {img:require('../assets/img/RedAlert/img_08.png'),title:'红色警戒2尤里的复仇',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-12',moreSize:'24M',size:'238M'},
                      {img:require('../assets/img/RedAlert/img_02.png'),title:'红色警戒2核战争',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-12',moreSize:'26M',size:'148M'},
                      {img:require('../assets/img/RedAlert/img_09.png'),title:'红色警戒2中国崛起',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-15',moreSize:'30M',size:'186M'},
                      {img:require('../assets/img/RedAlert/img_03.png'),title:'红色警戒2科技时代3.3',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-02',moreSize:'29M',size:'123M'},
                      {img:require('../assets/img/RedAlert/img_04.png'),title:'红色警戒3命令与征服',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-08',moreSize:'27M',size:'32M'},
                      {img:require('../assets/img/RedAlert/img_05.png'),title:'红色警戒3起义时刻',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-07',moreSize:'26M',size:'32M'},
                      {img:require('../assets/img/RedAlert/img_13.png'),title:'红色警戒3世界大战',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-11',moreSize:'27M',size:'429M'},
                    ],
                    "StarCraft":[
                      {img:require('../assets/img/StarCraft/xj_05.png'),title:'星际争霸1.08',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-09-12',moreSize:'15.78M',size:'1.35G'},
                      {img:require('../assets/img/StarCraft/xj_01.png'),title:'超级神族版1.0',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-09-12',moreSize:'1.53M',size:'3.24G'},
                      {img:require('../assets/img/StarCraft/xj_02.png'),title:'超级三族版1.2',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-09-12',moreSize:'601.3K',size:'1.87G'},
                      {img:require('../assets/img/StarCraft/xj_07.png'),title:'StarCraft1.16.1',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-09-12',moreSize:'12.79M',size:'1.93G'},
                      {img:require('../assets/img/StarCraft/xj_04.png'),title:'星际争霸高达世纪',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-09-12',moreSize:'332.8K',size:'197M'},
                      {img:require('../assets/img/StarCraft/xj_06.png'),title:'高达人口无限版',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-09-12',moreSize:'5.96M',size:'223M'},
                    ],
                    "CounterStrike":[
                      {img:require('../assets/img/CounterStrike/img_cs_01.png'),title:'《反恐精英CS1.6》',moreText:'攻略地图包',type:'FPS',language:'中文',updateTime:'2023-08-15',moreSize:'1.53M',size:'223M'},
                      {img:require('../assets/img/CounterStrike/img_cs_06.png'),title:'《反恐精英csv5》',moreText:'攻略地图包',type:'FPS',language:'中文',updateTime:'2023-08-15',moreSize:'364.9K',size:'934M'},
                      {img:require('../assets/img/CounterStrike/img_cs_02.png'),title:'《反恐精英CS1.5》',moreText:'攻略地图包',type:'FPS',language:'中文',updateTime:'2023-08-15',moreSize:'163.7K',size:'325M'},
                      {img:require('../assets/img/CounterStrike/img_cs_07.png'),title:'《反恐精英 cs起源》',moreText:'攻略地图包',type:'FPS',language:'中文',updateTime:'2023-08-15',moreSize:'795.8K',size:'3.2G'},
                    ],
                    "HoMM":[
                      {img:require('../assets/img/HoMM/img_01.png'),title:'英雄无敌3：深渊号角',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-15',moreSize:'17.39M',size:'853M'},
                      {img:require('../assets/img/HoMM/img_02.png'),title:'英雄无敌3：死亡阴影',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-08-15',moreSize:'4.6K',size:'623M'}
                    ],
                    "Diablo":[
                      {img:require('../assets/img/Diablo/img_01.png'),title:'暗黑破坏神1.13',moreText:'攻略地图包',type:'RPG角色扮演类',language:'中文',updateTime:'2023-08-15',moreSize:'1.29',size:'1.82G'},
                      {img:require('../assets/img/Diablo/img_02.png'),title:'暗黑破坏神2 毁灭之王',moreText:'攻略地图包',type:'RPG角色扮演类',language:'中文',updateTime:'2023-08-15',moreSize:'3.34M',size:'1.87G'}
                    ],
                    "Acgame":[
                      {img:require('../assets/img/Acgame/img_ji_01.png'),title:'恐龙快打',moreText:'攻略秘籍',type:'ACT',language:'中文',updateTime:'2023-09-22',moreSize:'6.8K',size:'207M'},
                      {img:require('../assets/img/Acgame/img_ji_02.png'),title:'三国战记',moreText:'攻略秘籍',type:'ACT',language:'中文',updateTime:'2023-09-22',moreSize:'2.58M',size:'98M'},
                      {img:require('../assets/img/Acgame/img_ji_03.png'),title:'西游记释厄传',moreText:'攻略秘籍',type:'ACT',language:'中文',updateTime:'2023-09-22',moreSize:'2.4K',size:'23.6M'},
                      {img:require('../assets/img/Acgame/img_ji_06.png'),title:'名将-圆桌骑士-惩罚者-铁钩船长',moreText:'攻略秘籍',type:'ACT',language:'中文',updateTime:'2023-09-22',moreSize:'8.6K',size:'70M'},
                    ],
                    "QiangTanDengLu":[
                      {img:require('../assets/img/QiangTanDengLu/qiangtan_01.png'),title:'《抢滩登陆战2002》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-12',moreSize:'2.5M',size:'99M'},
                      {img:require('../assets/img/QiangTanDengLu/qiangtan_03.png'),title:'《抢滩登陆战2003-空袭》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-12',moreSize:'2.4M',size:'80M'},
                      {img:require('../assets/img/QiangTanDengLu/qiangtan_02.png'),title:'《抢滩登陆战2004》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-12',moreSize:'3.2M',size:'115M'},
                      {img:require('../assets/img/QiangTanDengLu/qiangtan_04.png'),title:'《抢滩登陆战2005》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-12',moreSize:'2.9M',size:'96M'},
                      {img:require('../assets/img/QiangTanDengLu/qiangtan_06.png'),title:'《抢滩登陆战2006》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-12',moreSize:'3.4M',size:'88M'},
                      {img:require('../assets/img/QiangTanDengLu/qiangtan_05.png'),title:'《抢滩登陆战2007》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-12',moreSize:'2.2M',size:'107M'},
                    ],
                    "XueZhanShangHaiTan":[
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_01.png'),title:'《抗日血战上海滩》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'2.1M',size:'350M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_02.png'),title:'《抗日血战上海滩》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'3.3M',size:'347M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_03.png'),title:'《抢滩登陆战2002》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'3.6M',size:'99M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_04.png'),title:'《抢滩登陆战2003-空袭》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'3.9M',size:'80M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_05.png'),title:'《抢滩登陆战2004》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'3.5M',size:'115M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_06.png'),title:'《抢滩登陆战2005》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'2.2M',size:'96M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_07.png'),title:'《抢滩登陆战2006》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'2.7M',size:'88M'},
                      {img:require('../assets/img/XueZhanShangHaiTan/qiangtan_08.png'),title:'《抢滩登陆战2007》',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-09-15',moreSize:'2.8M',size:'107M'},
                    ],
                    "DiGuo":[
                      {img:require('../assets/img/DiGuo/img_diguo_01.png'),title:'帝国时代1+罗马复兴',type:'即时战略',moreText:'攻略秘籍',language:'中文',updateTime:'2023-09-15',moreSize:'2.5M',size:'1.2G'},
                      {img:require('../assets/img/DiGuo/img_diguo_02.png'),title:'帝国时代2HD+征服者',type:'即时战略',moreText:'攻略秘籍',language:'中文',updateTime:'2023-09-15',moreSize:'2.6M',size:'2.3G'},
                      {img:require('../assets/img/DiGuo/img_diguo_03.png'),title:'帝国帝国时代3酋长+亚洲王朝',type:'即时战略',moreText:'地图包',language:'中文',updateTime:'2023-09-15',moreSize:'30M',size:'2.5G'},
                      {img:require('../assets/img/DiGuo/img_diguo_04.png'),title:'帝国时代3拿破仑时代',type:'即时战略',moreText:'攻略秘籍',language:'中文',updateTime:'2023-09-15',moreSize:'2.9M',size:'1.1G'},
                      {img:require('../assets/img/DiGuo/img_diguo_05.png'),title:'帝国时代2非洲王国王朝',type:'即时战略',moreText:'攻略秘籍',language:'中文',updateTime:'2023-09-15',moreSize:'1.9M',size:'1.6G'},
                      {img:require('../assets/img/DiGuo/img_diguo_06.png'),title:'帝国时代4国家崛起',type:'即时战略',moreText:'攻略秘籍',language:'中文',updateTime:'2023-09-15',moreSize:'3M',size:'1.3G'},
                    ],
                    "JingDianHeJi":[
                      {img:require('../assets/img/JingDianHeJi/img_windows_01.png'),title:'蜘蛛纸牌',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'1.1K',size:'5M'},
                      {img:require('../assets/img/JingDianHeJi/img_windows_05.png'),title:'扫雷',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'1.6K',size:'2M'},
                      {img:require('../assets/img/JingDianHeJi/img_windows_02.png'),title:'三维弹球',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.4K',size:'12M'},
                      {img:require('../assets/img/JingDianHeJi/img_windows_06.png'),title:'空中接龙',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'1.1K',size:'7M'},
                      {img:require('../assets/img/JingDianHeJi/img_windows_03.png'),title:'红心大战',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'1.7K',size:'3M'},
                      {img:require('../assets/img/JingDianHeJi/img_windows_04.png'),title:'共和国之辉',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-10-10',moreSize:'2.96M',size:'138M'},
                      {img:require('../assets/img/JingDianHeJi/img_windows_08.png'),title:'冰封王座',moreText:'攻略地图包',type:'即时战略',language:'中文',updateTime:'2023-10-10',moreSize:'31.67M',size:'1.23G'},
                      {img:require('../assets/img/ZuMa/img_zuma_06.png'),title:'祖玛中文版',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.3K',size:'89M'},
                    ],
                    "BaoLiMoTuo":[
                      {img:require('../assets/img/BaoLiMoTuo/img_motuo_01.png'),title:'暴力摩托2002',moreText:'攻略',type:'竞速类赛车游戏',language:'中文',updateTime:'2023-10-10',moreSize:'10.2M',size:'46M'},
                      {img:require('../assets/img/BaoLiMoTuo/img_motuo_02.png'),title:'暴力摩托2004',moreText:'攻略',type:'竞速类赛车游戏',language:'中文',updateTime:'2023-10-10',moreSize:'10.6M',size:'76M'},
                      {img:require('../assets/img/BaoLiMoTuo/img_motuo_03.png'),title:'暴力摩托2008',moreText:'攻略',type:'竞速类赛车游戏',language:'中文',updateTime:'2023-10-10',moreSize:'12M',size:'78M'},
                      {img:require('../assets/img/BaoLiMoTuo/img_motuo_04.png'),title:'暴力摩托修改版',moreText:'攻略',type:'竞速类赛车游戏',language:'中文',updateTime:'2023-10-10',moreSize:'14M',size:'89M'},
                    ],
                    "ZuMa":[
                      {img:require('../assets/img/ZuMa/img_zuma_01.png'),title:'埃及祖玛4探索永恒',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.6K',size:'57M'},
                      {img:require('../assets/img/ZuMa/img_zuma_06.png'),title:'祖玛中文版',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.1K',size:'89M'},
                      {img:require('../assets/img/ZuMa/img_zuma_02.png'),title:'数字祖玛',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.3K',size:'79M'},
                      {img:require('../assets/img/ZuMa/img_zuma_05.png'),title:'印加祖玛',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.4K',size:'81M'},
                      {img:require('../assets/img/ZuMa/img_zuma_03.png'),title:'珠宝祖玛',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.3K',size:'55M'},
                      {img:require('../assets/img/ZuMa/img_zuma_04.png'),title:'蝴蝶祖玛',moreText:'游戏攻略',type:'休闲',language:'中文',updateTime:'2023-10-10',moreSize:'2.9K',size:'43M'},
                    ],
                    "ShiMingZhaoHuan":[
                      {img:require('../assets/img/ShiMingZhaoHuan/img_sm_03.png'),title:'使命召唤6',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-10-10',moreSize:'5.9M',size:'78G'},
                      {img:require('../assets/img/ShiMingZhaoHuan/img_sm_02.png'),title:'使命召唤4 ',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-10-10',moreSize:'5.2M',size:'110G'},
                      {img:require('../assets/img/ShiMingZhaoHuan/img_sm_04.png'),title:'使命召唤8',moreText:'攻略秘籍',type:'第一人称射击',language:'中文',updateTime:'2023-10-10',moreSize:'5.5M',size:'81G'},
                    ],
                    "GuiQi":[
                      {img:require('../assets/img/GuiQi/img_guiqi_01.png'),title:'鬼泣1',moreText:'攻略秘籍',type:'动作',language:'中文',updateTime:'2023-10-16',moreSize:'6.4M',size:'2.6G'},
                      {img:require('../assets/img/GuiQi/img_guiqi_04.png'),title:'鬼泣2',moreText:'攻略秘籍',type:'动作',language:'中文',updateTime:'2023-10-16',moreSize:'8.2M',size:'3.1G'},
                      {img:require('../assets/img/GuiQi/img_guiqi_02.png'),title:'鬼泣3 ',moreText:'攻略秘籍',type:'动作',language:'中文',updateTime:'2023-10-16',moreSize:'4.7M',size:'4.2G'},
                    ],
                    "MountBlade":[
                      {img:require('../assets/img/MountBlade/img_01.png'),title:'骑马与砍杀：火与剑',moreText:'攻略秘籍',type:'即时战略',language:'中文',updateTime:'2023-10-23',moreSize:'7.8M',size:'853M'},
                      {img:require('../assets/img/MountBlade/img_02.png'),title:'骑马与砍杀：战团',moreText:'攻略秘籍',type:'即时战略',language:'中文',updateTime:'2023-10-23',moreSize:'6.7M',size:'623M'}
                    ],
                    "Prototype":[
                      {img:require('../assets/img/Prototype/img_nue_01.png'),title:'虐杀原形1',moreText:'攻略秘籍',type:'动作冒险',language:'中文',updateTime:'2023-10-25',moreSize:'3.5M',size:'8.2G'},
                      {img:require('../assets/img/Prototype/img_nue_04.png'),title:'虐杀原形2',moreText:'攻略秘籍',type:'动作冒险',language:'中文',updateTime:'2023-10-25',moreSize:'2.4M',size:'11.7G'},

                    ],
                    "dll":[
                      {img:require('../assets/img/dll/img_directx_01.png'),title:'DirectX修复工具',updateTime:'2023-11-2',size:'13M'},
                      {img:require('../assets/img/dll/img_directx_02.png'),title:'微软VC运行库32位',updateTime:'2023-11-2',size:'18M'},
                      {img:require('../assets/img/dll/img_directx_03.png'),title:'微软VC运行库64位',updateTime:'2023-11-2',size:'16M'},
                    ],
                    "msvbcrt":[
                      {img:require('../assets/img/msvbcrt/img_directx_01.png'),title:'DirectX修复工具',updateTime:'2023-11-2',size:'13M'},
                      {img:require('../assets/img/msvbcrt/img_directx_02.png'),title:'微软VC运行库32位',updateTime:'2023-11-2',size:'18M'},
                      {img:require('../assets/img/msvbcrt/img_directx_03.png'),title:'微软VC运行库64位',updateTime:'2023-11-2',size:'16M'},
                    ],
                    "GTA":[
                      {img:require('../assets/img/GTA/img_nue_01.png'),title:'侠盗猎车手：罪恶都市',moreText:'攻略秘籍',type:'角色扮演',language:'中文',updateTime:'2023-11-20',moreSize:'6.2M',size:'612M'},
                      {img:require('../assets/img/GTA/img_nue_04.png'),title:'侠盗猎车手：圣安地列斯',moreText:'攻略秘籍',type:'角色扮演',language:'中文',updateTime:'2023-11-20',moreSize:'4.7M',size:'3.87G'},
                      {img:require('../assets/img/GTA/img_nue_02.png'),title:'侠盗猎车手：3',moreText:'攻略秘籍',type:'角色扮演',language:'中文',updateTime:'2023-11-20',moreSize:'5.9M',size:'684M'},
                    ],
                    "ResidentEvil":[  
                      {img:require('../assets/img/ResidentEvil/img_sheng_01.png'),title:'生化危机0高清版',moreText:'攻略秘籍',type:'AAG',language:'中文',updateTime:'2023-11-30',moreSize:'2.5M',size:'12G'},
                      {img:require('../assets/img/ResidentEvil/img_sheng_04.png'),title:'生化危机2重制版 ',moreText:'攻略秘籍',type:'AAG',language:'中文',updateTime:'2023-11-30',moreSize:'3.7M',size:'26G'},
                      {img:require('../assets/img/ResidentEvil/img_sheng_02.png'),title:'生化危机3重制版',moreText:'攻略秘籍',type:'AAG',language:'中文',updateTime:'2023-11-30',moreSize:'2.6M',size:'31G'},
                      {img:require('../assets/img/ResidentEvil/img_sheng_05.png'),title:'生化危机4终极高清版',moreText:'攻略秘籍',type:'AAG',language:'中文',updateTime:'2023-11-30',moreSize:'3.1M',size:'9.1G'},
                      {img:require('../assets/img/ResidentEvil/img_sheng_03.png'),title:'生化危机5黄金版',moreText:'攻略秘籍',type:'AAG',language:'中文',updateTime:'2023-11-30',moreSize:'2.9M',size:'8.2G'},
                    ]

                  },

                  newView:
                    {
                      "StarCraft":{
                        title: '星际争霸 攻略合集',
                        type:'即时战略',
                        language:'中文',
                        updateTime:'2023-08-15',
                        size:'1.73G',
                        moreSize:'36.96M'
                      },
                      "HoMM":{
                        title: '英雄无敌3 攻略合集',
                        type:'回合制策略游戏',
                        language:'中文',
                        updateTime:'2023-09-06',
                        size:'802M',
                        moreSize:'17.50M',
                      },
                      "Diablo":{
                        title: '暗黑破坏神 攻略合集',
                        type:'RPG角色扮演类',
                        language:'中文',
                        updateTime:'2023-09-06',
                        size:'142M',
                        moreSize:'3.63M',
                      },
                      "XueZhanShangHaiTan":{
                        title: '血战上海滩',
                        type:'即时战略',
                        language:'中文',
                        updateTime:'2023-09-12',
                        size:'350M',
                      },
                      "MountBlade":{
                        title: '骑马与砍杀 攻略合集',
                        type:'ARPG',
                        language:'中文',
                        updateTime:'2023-10-23',
                        size:'2.5G',
                        moreSize:'15.7M'
                      }
                    }
                  ,

                  topPhoto:{
                    "Warcraft":require('../assets/img/Warcraft/img_top.png'),
                    "RedAlert":require('../assets/img/RedAlert/img_top.png'),
                    "StarCraft":require('../assets/img/StarCraft/img_top.png'),
                    "CounterStrike":require('../assets/img/CounterStrike/img_top.png'),
                    "HoMM":require('../assets/img/HoMM/img_top.png'),
                    "Diablo":require('../assets/img/Diablo/img_top.png'),
                    "Acgame":require('../assets/img/Acgame/img_top.png'),
                    "QiangTanDengLu":require('../assets/img/QiangTanDengLu/img_top.png'),
                    "XueZhanShangHaiTan":[
                      require('../assets/img/XueZhanShangHaiTan/img_top01.png'),
                      require('../assets/img/XueZhanShangHaiTan/img_top02.png'),
                    ],
                    "DiGuo":require('../assets/img/DiGuo/img_top.png'),
                    "JingDianHeJi":require('../assets/img/JingDianHeJi/img_top.png'),
                    "BaoLiMoTuo":require('../assets/img/BaoLiMoTuo/img_top.png'),
                    "ZuMa":require('../assets/img/ZuMa/img_top.png'),
                    "ShiMingZhaoHuan":require('../assets/img/ShiMingZhaoHuan/img_top.png'),
                    "GuiQi":require('../assets/img/GuiQi/img_top.png'),
                    "MountBlade":require('../assets/img/MountBlade/img_top.png'),
                    "Prototype":require('../assets/img/Prototype/img_top.png'),
                    "dll":require('../assets/img/dll/img_top.png'),
                    "msvbcrt":require('../assets/img/msvbcrt/img_top.png'),
                    "GTA":require('../assets/img/GTA/img_top.png'),
                    "ResidentEvil":require('../assets/img/ResidentEvil/img_top.png'),
                  },

                  bottomPic: {
                    "CounterStrike":require('../assets/img/CounterStrike/img_bottom.png'),
                    "BaoLiMoTuo":require('../assets/img/BaoLiMoTuo/img_bottom.png'),
                    "ShiMingZhaoHuan":require('../assets/img/ShiMingZhaoHuan/img_bottom.png'),
                    "GuiQi":require('../assets/img/GuiQi/img_bottom.png'),
                    "Prototype":require('../assets/img/Prototype/img_bottom.png'),
                    "dll":require('../assets/img/dll/img_bottom.png'),
                    "msvbcrt":require('../assets/img/msvbcrt/img_bottom.png'),
                    "GTA":require('../assets/img/GTA/img_bottom.png'),
                  },
                  topPic:'',
                  companyName:'',
                  companyArr:[
                    {host:'localhost:8080',name:'芜湖界佳网络科技有限公司'},
                    {host:'youxii.ncdsnc.com',name:'芜湖界佳网络科技有限公司'},
                  ],

                  yxCompany: [
                  ],

                  isIntruce: {
                      'StarCraft':'《星际争霸》设置在一个科幻的故事背景里，围绕三个假想的银河种族展开，它们是：Protoss（一个纯精神、纯能量的种族），Zerg（一个纯肉体、纯生物的种族）和Terran（原版中来自地球的流放试验品——罪犯，资料片中UED为地球的人类殖民者）,所有软件均经过百度杀毒中心认证,无病毒,安装后无弹窗广告,绿色,安全请放心下载,技术客服24小时在线解决安装以及游戏问题！',
                      'CounterStrike':'Counter-Strike 1.5是一款于2002年发布的多人在线第一人称射击（FPS）游戏，是一个团队合作的射击游戏，分为恐怖分子和反恐特种部队两个队伍。恐怖分子的目标是完成特定任务，如炸弹安装或人质解救，而反恐特种部队则需要阻止他们并保护目标。游戏地图多样，涵盖了各种场景，包括城市街道、军事基地、工厂和恐怖分子的藏身处等。 CS 1.5在原始版本的基础上进行了一些重要改进和增强。这个版本引入了新的地图、武器和游戏模式。',
                      'HoMM':'游戏的故事剧情刚开始时，游戏里的凯丝琳女王回到了她的家乡Erathia，发现父王的王国已变得混乱不堪，这才得知她那位仁慈的父王已经去世，女王也就理所当然地替先父打理着国家。然而就在王国眼看着就要恢复和平宁静时，一个自称不死军团的组织冒了出来，还占据了凯丝琳父王的肉体，操纵着父王的肉身向凯丝琳发起了挑战。',
                      'Diablo':'故事发生在DIABLO被世间的勇士杀死后的五百年，DIABLO的追随者NAKRUL召集了地狱里剩下的所有魔兽，用魔法召唤出DIABLO的幽灵，他们栖身在一个古代属于恶魔的地穴中，他把所有的魔兽组织 成为一支前所未见的恶魔军队，现在，NAKRUL发出一句恐吓宣言;他将比DIABLO更具有破坏力和杀伤力。',
                      'XueZhanShangHaiTan':'游戏的敌人有普通日本士兵，还有特殊兵种日本浪人、忍者、军曹、机枪兵、自杀式袭击者、装甲车等，和科幻的，类似于僵尸的“生化兵”。游戏有简单的伤害判定系统，射中头部（或同等要害）对于一般的敌人可一枪毙命，而射击身体其他部位，可能需要数发子弹才能将敌人消灭。特殊兵种的攻击力和生命力都较一般敌兵为高。',
                      'MountBlade':'一个策略/动作类角色扮演游戏。创建一个角色，投身中世纪战场，从事外交、锻造、贸易和征服新的土地。集结你的军团奔赴战场，在大规模实时战斗中体验深奥但又直观的基于技能的战斗系统，与你忠诚的战士们并肩作战、取得胜利。'
                  },

                  downloadNoJump:[
                  ]
                }
            },
            mounted() {
              this.list = this.isArr[this.$route.meta.name]
              this.topPic = this.topPhoto[this.$route.meta.name]
              let midArr = [...this.yxCompany,...this.companyArr]
              this.companyName = midArr.find(item =>item.host == window.location.host).name || ''
            },
            methods:{
                getUrl() {
                    if (this.clickBtuuonRepeat) return
                    let params = {
                      tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id'),
                      tg_type: sessionStorage.getItem('tg_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_type')),
                      cid: sessionStorage.getItem('pdf_id') == 'null' ? 0 : Number(sessionStorage.getItem('pdf_id')),
                      tg_url_type: sessionStorage.getItem('tg_url_type') == 'null' ? 0 : Number(sessionStorage.getItem('tg_url_type')),
                      url: window.location.host
                    }
              
                    if ( params.tg_id == '0' ) {
                        params.cid = 0
                    }

                    let url = 'https://api.wetalk.kafankj.com/tg/getTgUrl'
                    axios.post(url, params).then((res) => {
                      let downUrl = ''
              
                      if (res.data.code == 0) {
                        downUrl = res.data.data.detail.tg_url
                        const a = document.createElement('a')
                        a.href = downUrl
                        document.body.appendChild(a)
                        a.click()
                        a.remove()
                        
                        let isCallbackurl = 'https://api.wetalk.kafankj.com/tg/download/callback'
                
                        axios.post(isCallbackurl, { tg_id: sessionStorage.getItem('tg_id') == 'null' ? '0' : sessionStorage.getItem('tg_id') }).then((res) => {
                          console.log(res);
                        })
                      } else {
                        this.$message.error(res.data.msg)
                      }
              
                    }) .catch(() =>{
                      this.$message.error('网络错误，请稍后再试')
                    })

                    this.click_btn('点击下载按钮')

                    this.clickBtuuonRepeat = true
                    setTimeout(() => {
                      this.clickBtuuonRepeat = false
                    }, 2000)
              
                  },
                click_btn(category,label = '',action = 'click') {
                try {
                  // let domainHost = window.location.host
                  // let result_cnzz = this.hostArr.find((item) => item.host == domainHost) || { src: 'https://hm.baidu.com/' }
                  // if (result_cnzz && result_cnzz.src) {
                  //   var _hmt = _hmt || []
                  //   ;(function () {
                  //     var hm = document.createElement('script')
                  //     hm.src = result_cnzz.src ? result_cnzz.src : ''
                  //     var s = document.getElementsByTagName('script')[0]
                  //     s.parentNode.insertBefore(hm, s)
                  //   })()
                  // }
                  console.log(category,label = '',action = 'click');
                    _hmt.push(['_trackEvent',category,action,label])
                } catch (error) {
                  console.log(error);
                }
                },


                toolDownLoad(index = 0){
                  let isUrl  = index?index==2?'https://api.youxi.ncdsnc.com/storage/game/msvbcrt_x64.exe':'https://api.youxi.ncdsnc.com/storage/game/msvbcrt_x86.exe':''

                  if ( isUrl ) {
                    var a = document.createElement('a');
                    a.style.display = 'none'
                    a.href = isUrl
                    document.body.appendChild(a)
                    a.target = '_blank'
                    a.download = this.getUrlName(isUrl)
                    a.click() //执行下载
                    window.URL.revokeObjectURL(a.href)
                    document.body.removeChild(a)
                  } else {
                    this.downloadOneUrl()
                    setTimeout(() => {
                      this.downloadTwoUrl()
                    }, 2000);
                  }
                },

                downloadOneUrl(){
                  var a = document.createElement('a');
                    a.style.display = 'none'
                    a.href = 'https://api.youxi.ncdsnc.com/storage/game/msvbcrt_x86.exe'
                    document.body.appendChild(a)
                    a.target = '_blank'
                    a.download = 'msvbcrt_x86.exe'
                    a.click() //执行下载
                    window.URL.revokeObjectURL(a.href)
                    document.body.removeChild(a)
                },
                downloadTwoUrl(){
                  var a = document.createElement('a');
                    a.style.display = 'none'
                    a.href = 'https://api.youxi.ncdsnc.com/storage/game/msvbcrt_x64.exe'
                    document.body.appendChild(a)
                    a.target = '_blank'
                    a.download = 'msvbcrt_x64.exe'
                    a.click() //执行下载
                    window.URL.revokeObjectURL(a.href)
                    document.body.removeChild(a)
                },

                downloadFn(title){
                  const cid = sessionStorage.getItem('pdf_id') !== 'null' ? Number(sessionStorage.getItem('pdf_id')): 0

                  const aid =  sessionStorage.getItem('tg_id') !== 'null'? sessionStorage.getItem('tg_id'): '0'
                  let isUrl = ''
                  
                  // if ( this.downloadNoJump.includes(window.location.host) ) {
                    this.track(1,'','请求下载接口')
                    let requestUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/game/get_download_url`
                    axios.post(requestUrl,{game_id: this.$route.name+'i',cid: cid,aid: aid}).then(res =>{
                      if ( res.data.code == 200 ) {

                        const isUrl = `${window.location.origin}${res.data.data.url}`

                        this.track(1,isUrl,title)
                        var a = document.createElement('a');
                        a.style.display = 'none'
                        a.href = isUrl
                        document.body.appendChild(a)
                        a.target = '_blank'
                        a.download = this.getUrlName(res.data.data.url)
                        a.click() //执行下载
                        window.URL.revokeObjectURL(a.href)
                        document.body.removeChild(a)
      
                      } else {
                        setTimeout(() => {
                          this.downloadFn(title)
                        }, 2000);
                      }
                    })
                  // } else {
                  //   isUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/game/download/`+ this.$route.name +'/'+ cid +'/'+ aid
                  //   this.track(1,isUrl,title)
                  //   var a = document.createElement('a');
                  //   a.style.display = 'none'
                  //   a.href = isUrl
                  //   document.body.appendChild(a)
                  //   a.target = '_blank'
                  //   // a.download = this.getUrlName(res.data.data.url)
                  //   a.click() //执行下载
                  //   window.URL.revokeObjectURL(a.href)
                  //   document.body.removeChild(a)
                  // }
                },

                getUrlName(title){
                  let titleArr = title.split('/')
                  return titleArr[titleArr.length - 1]
                },

                track(isNum = 0,url = '',title = ''){
                  let currentUrl = url == '' ? window.location.href : url
                  let eventArr = ['PAGE_LOAD','BTN_CLICK_DOWNLOAD']
                  const gameId = this.$route.meta.name + 'i'
                  console.log(gameId,'route');
                  let params = {
                    "device_no": this.uuid().get(),
                    "game_id": gameId, // Warcraft RedAlert
                    "data": {
                      "host": window.location.host,
                      "url": window.location.href,
                      "os": navigator.userAgent,
                      "mac": "",
                      "guid": this.uuid().get(), //
                      "version": "1.0.0", //版本号
                      "cid": sessionStorage.getItem('pdf_id') !== 'null'? Number(sessionStorage.getItem('pdf_id')): 0, // cid渠道ID
                      "click_id": sessionStorage.getItem('tg_id') !== 'null'?sessionStorage.getItem('tg_id'): '0', // 广告ID 原tg_id
                      "tg_type":  sessionStorage.getItem('tg_type') !== 'null'? Number(sessionStorage.getItem('tg_type')): 0, // 0 默认，1，百度，2, 360
                      "tg_url_type": sessionStorage.getItem('tg_url_type') !== 'null'? Number(sessionStorage.getItem('tg_type')): 0, // 0 根据前端配置
                      "types": eventArr[isNum], // PAGE_LOAD BTN_CLICK_DOWNLOAD
                      "value": JSON.stringify({
                          "url":currentUrl,
                          "element": title
                      }),
                    }
                  }
                  const isUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/game/track`
                  axios.post(isUrl,params).then((res) =>{
                    console.log(res);
                  }).catch((error) =>{
                    this.$message.error('请求失败');
                  })
                },
                uuid(){
                    return {
                        create: function() {
                            let s = [];
                            let hexDigits = '0123456789abcdef';
                            for (let i = 0; i < 36; i++) {
                                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                            }
                            s[14] = '4';
                            // @ts-ignore
                            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
                            s[8] = s[13] = s[18] = s[23] = '';
                            return s.join('');
                        },
                        get: function() {
                            try {
                                if (!window.localStorage.getItem('_g_uuid')) {
                                    const _uuid = this.create();
                                    window.localStorage.setItem('_g_uuid', _uuid);
                                    return _uuid;
                                } else {
                                    return window.localStorage.getItem('_g_uuid');
                                }
                            } catch (e) {
                                return false;
                            }
                
                        },
                    };
                }
            }
        })
    }
}